<template>
  <div class="Feedback">
    <div class="bgimg">
      <div class="Feedback_inpBox">
        <div class="title">BUG与意见反馈</div>
        <div class="inp_box">
          <div class="l_inp">
            <div class="txt">称呼:</div>
            <el-input placeholder="输入姓名" v-model="name" clearable>
            </el-input>
          </div>
          <div class="r_inp">
            <div class="txt1">联系方式(邮箱):</div>
            <el-input placeholder="输入联系方式" v-model="phone" clearable>
            </el-input>
          </div>
        </div>

        <div class="Feedback_inp">
          <div class="txt">描述:</div>

          <el-input
            type="textarea"
            :rows="7"
            placeholder="非常感谢您的真知灼见，我们将迅速落实并反馈。"
            v-model="textarea"
          >
          </el-input>
        </div>
        <div class="Feedback_inp1">
          <div class="txt">上传截图:</div>

          <FeedbackUpload ref="FeedbackUpload1" />
          <div class="btn" @click="addFeedback">提交反馈</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addContactUs } from "api/introduce";
import FeedbackUpload from "components/disk/FeedbackUpload.vue";
export default {
  name: "Feedback",
  components: {
    FeedbackUpload,
  },
  data() {
    return {
      name: "",
      phone: "",
      textarea: "",
    };
  },

  methods: {
    async addFeedback() {
      // let reg = /^1[0-9]{10}$/;
      // if (!this.phone) {
      //   this.$message({
      //     type: "error",
      //     message: "请输入手机号!"
      //   });
      //   return;
      // }
      // if (!reg.test(this.phone)) {
      //   this.$message({
      //     type: "error",
      //     message: "请输入正确的手机号!"
      //   });
      //   return;
      // }

      if (this.textarea == "") {
        this.$message({
          type: "error",
          message: "请输入反馈内容!",
        });
        return;
      }
      const data = {
        name: this.name,
        mobile: this.phone,
        platform: 3,
        content: this.textarea,
        type: 1,
        pictures: this.$store.state.imgList,
      };
      const res = await addContactUs(data);
      if (res.code != 200) return;

      this.$message({
        type: "success",
        message: "谢谢您的反馈,我们会尽快处理的!",
      });
      this.name = "";
      this.phone = "";
      this.textarea = "";
      this.$refs.FeedbackUpload1.img = [];
      this.$refs.FeedbackUpload1.arr = [];
      this.$store.commit("GetimgList", []);
    },
  },
};
</script>

<style lang="scss" scoped>
.Feedback {
  position: absolute;
  left: 0;
  height: 1000px;
  width: 100%;
  background-color: #fff;
  margin-top: -30px;

  .bgimg {
    width: 100%;
    background: url("~assets/images/feedbackbgc.png") no-repeat;
    background-size: 100% 100%;
    height: 670px;

    .Feedback_inpBox {
      width: 1200px;
      height: 520px;
      box-sizing: border-box;
      padding: 50px 90px;
      background: #ffffff;
      box-shadow: 0px 9px 24px 0px rgba(129, 135, 144, 0.13);
      border-radius: 20px;
      margin: 0 auto;
      transform: translateY(330px);

      .title {
        font-size: 30px;
        font-weight: bold;

        span {
          font-size: 20px;
          color: #999999;
        }
      }

      .inp_box {
        margin: 40px 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .l_inp,
        .r_inp {
          display: flex;
          align-items: center;
          .txt {
            width: 50px;
            margin-right: 15px;
          }
          .txt1 {
            width: 130px;

            margin-left: 10px;
          }

          .el-input {
            width: 396px;
          }
        }
      }

      .Feedback_inp {
        display: flex;

        .txt {
          width: 50px;
          margin-right: 20px;
        }

        .el-textarea {
          ::v-deep .el-textarea__inner {
            resize: none;
          }
        }
      }
      .Feedback_inp1 {
        margin-top: 40px;
        display: flex;
        width: 100%;
        .txt {
          margin-right: 20px;
        }
      }
      .btn {
        cursor: pointer;
        transform: translateX(70px);
        float: right;
        width: 240px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        background-image: linear-gradient(to right, #3e88f3, #9ac3fe);
      }
    }
  }
}
</style>
